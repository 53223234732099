import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

// Components
import JaLayout from '../../components/JaLayout';

// Language
import { getPackOf } from '../../lang';
const LANG = getPackOf('ja');

const QA = () => {
  const [query, setQuery] = useState('');

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/qa-ja.md" }) {
        frontmatter {
          qa {
            a
            q
            category
          }
        }
      }
    }
  `);

  const filteredQa = data.markdownRemark.frontmatter.qa.filter((el) => {
    if (query) return el.category === query;
    return null;
  });
  const qa = query ? filteredQa : data.markdownRemark.frontmatter.qa;

  return (
    <JaLayout>
      <section id="page_bn" className="pb-1" style={{ backgroundColor: ' #efefef', marginTop: '-16px' }}>
        <div className="container">
          <div id="breadcrumb">
            <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
              <div className="col-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb small text-white pt-3 pb-2 bg-js" style={{ backgroundColor: '#1169ac' }}>
                    <li className="breadcrumb-item">
                      <Link to="/ja" style={{ color: '#fff' }}>
                        {LANG.首頁}
                      </Link>
                    </li>
                    <li className="breadcrumb-item">{LANG.產品QA}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="case" className="my-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-left">
              <p className="h3">
                <strong>{LANG.產品QA}</strong>
              </p>
              <br />
              <p>
                {LANG.產品QA內文1}
                <Link to="/ja#Location">{LANG.產品QA內文2}</Link>
              </p>
            </div>
            <div id="" className="col-12">
              <hr />
            </div>
          </div>
        </div>
      </section>
      <section id="qa" className="container mb-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dropdown float-right">
                <button
                  className="btn btn-sm btn-warning dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {!query ? LANG.分類方式 : query}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#" onClick={() => setQuery('')} key="All">
                    All
                  </a>
                  {['銷售洽詢', '出貨相關', '太陽能支架應用', '品質與認證', '板金工藝設計'].map((el) => {
                    return (
                      <a className="dropdown-item" href="#" onClick={() => setQuery(LANG[el])} key={el}>
                        {LANG[el]}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="accordion" id="accordionExample">
                {qa &&
                  qa.map((el, i) => {
                    return (
                      <div className="card my-1 border" key={el.q}>
                        <div className="card-header" id={`heading${i}`}>
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link px-0"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${i}`}
                              aria-expanded="true"
                              aria-controls={`collapse${i}`}
                            >
                              <strong>Q:</strong> {el.q}
                            </button>
                          </h5>
                        </div>
                        <div
                          id={`collapse${i}`}
                          className="collapse"
                          aria-labelledby={`heading${i}`}
                          data-parent="#accordionExample"
                        >
                          <div className="card-body ml-4">
                            <strong>A: </strong> <p style={{ whiteSpace: 'pre-line' }}>{el.a}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </JaLayout>
  );
};

export default QA;
